const FRONT_ENV_DEV_URL = 'http:localhost:3001'
const FRONT_ENV_STAGING_URL = 'https://staging.tippingpoint.app'
const FRONT_ENV_PROD_URL = 'https://tippingpoint.app'

export const PRIVY_APP_ID = 'clvg1ubgw0c3zstlbp13v5hsl'

export const EVENT_TYPES = {
  DYNAMIC: 'Dynamic',
  STATIC: 'Static',
  SPECIFIC_FUNDRAISING: 'Specific Fundraising',
  GENERAL_FUNDRAISING: 'General Fundraising',
}
export function generateEventLink(eventId, expired = false) {
  const url =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? `${FRONT_ENV_PROD_URL}/${expired ? 'past' : ''}event/${eventId}`
      : process.env.REACT_APP_ENVIRONMENT === 'development'
      ? `${FRONT_ENV_DEV_URL}/${expired ? 'past' : ''}event/${eventId}`
      : `${FRONT_ENV_STAGING_URL}/${expired ? 'past' : ''}event/${eventId}`
  return url
}

/**
 * For display purposes on UI, e.g. "1000000" => "1.00", for $ amount
 */
export function convertIntTo2DecimalFloat(value) {
  const rounded6DecimalValue = Math.round((value / 1e6) * 1e6) / 1e6

  return parseFloat(rounded6DecimalValue).toFixed(2)
}

export const USDC_DECIMALS = 1e6

export const getMyContributionAmount = (event, currentEthAddress) => {
  const currentUserToEvent = event?.userToEvents.find(
    (userToEvent) => userToEvent?.user?.ethAddress === currentEthAddress
  )
  if (currentUserToEvent?.optedIn) {
    return currentUserToEvent?.currentContribution
  } else {
    return 0
  }
}

export const getPrettyDateString = (date) => {
  let month
  switch (date.getMonth()) {
    case 0:
      month = 'January'
      break
    case 1:
      month = 'February'
      break
    case 2:
      month = 'March'
      break
    case 3:
      month = 'April'
      break
    case 4:
      month = 'May'
      break
    case 5:
      month = 'June'
      break
    case 6:
      month = 'July'
      break
    case 7:
      month = 'August'
      break
    case 8:
      month = 'September'
      break
    case 9:
      month = 'October'
      break
    case 10:
      month = 'November'
      break
    case 11:
      month = 'December'
      break
    default:
      month = ''
      break
  }

  let hours = date.getHours() % 12
  hours = hours == 0 ? 12 : hours

  let minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes

  let ampm = date.getHours() >= 12 ? 'PM' : 'AM'

  return (
    month +
    ' ' +
    date.getDate() +
    ', ' +
    date.getFullYear() +
    ' at ' +
    hours +
    ':' +
    minutes +
    ' ' +
    ampm
  )
}

export const EVENT_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  TIPPED: 'TIPPED',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED',
  INACTIVE: 'INACTIVE',
}

export const matchYoutubeURL = (url) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  } else {
    throw new Error('No match')
  }
}

export const OFAC_SANCTIONED_COUNTRY_CODES = [
  "UA",  // Crimea region of Ukraine
  "CU",  // Cuba
  "IR",  // Iran
  "KP",  // North Korea
  "SY",  // Syria
  // Donetsk People's Republic (DNR) and Luhansk People's Republic (LNR) are not officially recognized, so no codes.
  "RU",  // Russia
  "BY",  // Belarus
  "VE",  // Venezuela
  "AF",  // Afghanistan
  "CF",  // Central African Republic
  "CG",  // Congo (Republic of the Congo)
  "CD",  // Congo (Democratic Republic of the Congo)
  "ET",  // Ethiopia
  "HK",  // Hong Kong
  "IQ",  // Iraq
  "LB",  // Lebanon
  "LY",  // Libya
  "ML",  // Mali
  "NI",  // Nicaragua
  "SO",  // Somalia
  "SD",  // Sudan
  "SS",  // South Sudan
  "YE",  // Yemen
  "ZW",  // Zimbabwe
  "AL",  // Albania
  "BA",  // Bosnia and Herzegovina
  "BG",  // Bulgaria
  "HR",  // Croatia
  "XK",  // Kosovo (partially recognized)
  "ME",  // Montenegro
  "MK",  // North Macedonia
  "RO",  // Romania
  "RS",  // Serbia
  "SI",  // Slovenia
];

export const getUserIP = async () => {
  return fetch('https://api.ipify.org?format=json')
  .then((response) => response.json())
  .then((data) => {
    return data.ip
  })
  .catch((error) => {
    console.error('Error fetching IP', error)
    return null
  })
}

export const OFAC_SANCTIONED_ADDRESSES = [
  '0x098b716b8aaf21512996dc57eb0615e2383e2f96',
  '0xa0e1c89ef1a489c9c7de96311ed5ce5d32c20e4b',
  '0x3cffd56b47b7b41c56258d9c7731abadc360e073',
  '0x53b6936513e738f44fb50d2b9476730c0ab3bfc1',
  '0x35fb6f6db4fb05e6a4ce86f2c93691425626d4b1',
  '0xf7b31119c2682c88d88d455dbb9d5932c65cf1be',
  '0x3e37627deaa754090fbfbb8bd226c1ce66d255e9',
  '0x08723392ed15743cc38513c4925f5e6be5c17243',
  '0x7f367cc41522ce07553e823bf3be79a889debe1b',
  '0xd882cfc20f52f2599d84b8e8d58c7fb62cfe344b',
  '0x901bb9583b24d97e995513c6778dc6888ab6870e',
  '0xa7e5d5a720f06526557c513402f2e6b5fa20b008',
  '0x9f4cda013e354b8fc285bf4b9a60460cee7f7ea9',
  '0x3cbded43efdaf0fc77b9c55f6fc9988fcc9b757d',
  '0xfec8a60023265364d066a1212fde3930f6ae8da7',
  '0x7ff9cfad3877f21d41da833e2f775db0569ee3d9',
  '0xc2a3829f459b3edd87791c74cd45402ba0a20be3',
  '0x3ad9db589d201a710ed237c829c7860ba86510fc',
  '0x12d66f87a04a9e220743712ce6d9bb1b5616b8fc',
  '0x47ce0c6ed5b0ce3d3a51fdb1c52dc66a7c3c2936',
  '0x910cbd523d972eb0a6f4cae4618ad62622b39dbf',
  '0xa160cdab225685da1d56aa342ad8841c3b53f291',
  '0xd4b88df4d29f5cedd6857912842cff3b20c8cfa3',
  '0xfd8610d20aa15b7b2e3be39b396a1bc3516c7144',
  '0x07687e702b410fa43f4cb4af7fa097918ffd2730',
  '0x23773e65ed146a459791799d01336db287f25334',
  '0x22aaa7720ddd5388a3c0a3333430953c68f1849b',
  '0x03893a7c7463ae47d46bc7f091665f1893656003',
  '0x2717c5e28cf931547b621a5dddb772ab6a35b701',
  '0xd21be7248e0197ee08e0c20d4a96debdac3d20af',
  '0x39d908dac893cbcb53cc86e0ecc369aa4def1a29',
  '0x4f47bc496083c727c5fbe3ce9cdf2b0f6496270c',
  '0x38735f03b30fbc022ddd06abed01f0ca823c6a94',
  '0x97b1043abd9e6fc31681635166d430a458d14f9c',
  '0xb6f5ec1a0a9cd1526536d3f0426c429529471f40',
  '0x9c2bc757b66f24d60f016b6237f8cdd414a879fa',
  '0xdcbeffbecce100cce9e4b153c4e15cb885643193',
  '0x5f48c2a71b2cc96e3f0ccae4e39318ff0dc375b2',
  '0x5a7a51bfb49f190e5a6060a5bc6052ac14a3b59f',
  '0xed6e0a7e4ac94d976eebfb82ccf777a3c6bad921',
  '0x797d7ae72ebddcdea2a346c1834e04d1f8df102b',
  '0x931546d9e66836abf687d2bc64b30407bac8c568',
  '0x43fa21d92141ba9db43052492e0deee5aa5f0a93',
  '0x6be0ae71e6c41f2f9d0d1a3b8d0f75e6f6a0b46e',
  '0x530a64c0ce595026a4a556b703644228179e2d57',
  '0x983a81ca6fb1e441266d2fbcb7d8e530ac2e05a2',
  '0x961c5be54a2ffc17cf4cb021d863c42dacd47fc1',
  '0xfac583c0cf07ea434052c49115a4682172ab6b4f',
  '0xe950dc316b836e4eefb8308bf32bf7c72a1358ff',
  '0x21b8d56bda776bbe68655a16895afd96f5534fed',
  '0x175d44451403edf28469df03a9280c1197adb92c',
  '0xf3701f445b6bdafedbca97d1e477357839e4120d',
  '0x19f8f2b0915daa12a3f5c9cf01df9e24d53794f7',
]

export const getMyCreatorStatus = (event, currentEthAddress) => {
  const currentUserToEvent = event?.userToEvents.find(
    (userToEvent) => userToEvent?.user?.ethAddress === currentEthAddress
  )
  return currentUserToEvent ? currentUserToEvent?.isCreator : false
}

export const getMyOptInStatus = (event, currentEthAddress) => {
  const currentUserToEvent = event?.userToEvents.find(
    (userToEvent) => userToEvent?.user?.ethAddress === currentEthAddress
  )
  return currentUserToEvent ? currentUserToEvent?.isOptIn : false
}

export const isCreator = (event, ethAddress) => {
  const isCreator = event?.creatorEthAddress === ethAddress
  return isCreator
}

export const validateJWTTokenExpiration = (jwtToken) => {
  const tokenPayload = JSON.parse(atob(jwtToken.split('.')[1]))
  const expiry = tokenPayload.exp
  const now = Math.floor(Date.now() / 1000)
  return now < expiry
}
