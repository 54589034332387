import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccount, useConnect } from 'wagmi'
import { watchAccount } from '@wagmi/core'

import { validateJWTTokenExpiration } from '../utils/utils'
import {
  axiosInstance,
  setAxiosAuthInstance,
  unsetAxiosAuthInstance,
} from './webClient'

import { config as wagmiConfig } from '../components/web3ModalProvider'
import { useDisconnect } from 'wagmi'
import { OFAC_SANCTIONED_ADDRESSES } from '../utils/utils'
import { useWeb3Modal } from '@web3modal/wagmi/react'

const fetchUserData = async (address) => {
  try {
    const res = await axiosInstance.get(`/user/${address}`)
    return res.data
  } catch (err) {
    console.error('fetchUserData err', err)
    throw new Error(err)
  }
}



let address = ''
let connector = null

export const useWeb3Provider = () => {
  const { connector } = useAccount(wagmiConfig)

  const getProvider = async () => {
    try {
      const provider = await connector.getProvider()
      console.log('getProvider', { provider })
      return provider
    } catch (err) {
      console.error('getProvider err', err)
    }
  }

  return {
    getProvider,
  }
}

// sometimes the wallet provider will auto-connect to the wallet, this will trigger the onAccountChange function,
// we want to make sure this only happens when the user actually clicks the connect button
let userBroken = false

export const useWeb3Open = () => {
  const { open: wagmiOpen } = useWeb3Modal(wagmiConfig)

  return {
    open: function () {
      userBroken = true
      wagmiOpen()
    },
  }
}

export const useWeb3Connect = () => {
  const { connect: wagmiConnect, connectors: wagmiConnectors } =
    useConnect(wagmiConfig)

  return {
    connect: function (args) {
      userBroken = true
      wagmiConnect(args)
    },
    connectors: wagmiConnectors,
  }
}

const getJWTTokenwithSignature = async (address, signature, message) => {
  return await axiosInstance
    .post(`/login/attempt`, {
      signature,
      message,
      address,
    })
    .then((response) => {
      console.log('signInWithSignedSiwe', { response })
      const token = response.data.token
      return token
    })
    .catch((err) => {
      console.log('signInWithSignedSiwe err', { err })
      throw new Error(err)
    })
}

const fetchSiweMsg = async (address) => {
  return await axiosInstance
    .get(`/login/siwemsg/${address}`)
    .then((response) => {
      console.log('fetchSiweMsg res', { response })
      return response.data.msgToSign
    })
    .catch((err) => {
      console.log('fetchSiweMsg err', { err })
      throw new Error(err)
    })
}

// const client = usePublicClient({config: wagmiConfig});
const signJwtMsg = async (msg, connector, address) => {
  if (!connector) {
    throw new Error('No connector')
  }
  const provider =await connector.getProvider()
  const signer = await provider.getSigner()
  const signature = await signer.signMessage(msg)
  // const signature = await signMessage(wagmiConfig, {message: msg})

  // const isValid = await client.verifyMessage({
  //     address: address,
  //     message: msg,
  //     signature,
  //   });
  // console.log('is it effin valid? 3', { })
  return signature
}

const getNewJWTToken = async (address, connector) => {
  try {
    // get msg to sign
    const msgToSign = await fetchSiweMsg(address)
    // sign msg
    const signature = await signJwtMsg(msgToSign, connector, address)
    // send signed msg to backend
    const token = await getJWTTokenwithSignature(address, signature, msgToSign)

    return token
  } catch (err) {
    console.error('getNewJWTToken err', err)
    throw new Error(err)
  }
}

export const useWeb3Service = () => {
  const [currentUser, setCurrentUser] = React.useState({})

  const [status, setStatus] = React.useState('')

  const [address, setAddress] = React.useState('')

  const { disconnect: wagmiDisconnect } = useDisconnect()
  const { open: wagmiOpen } = useWeb3Modal(wagmiConfig)
  const { connector } = useAccount(wagmiConfig)

  const navigate = useNavigate()

  const open = (args) => {
    userBroken = true
    wagmiOpen(args)
  }

  const authenticate = async () => {
    try {
      console.log('authenticate 1', { connector })
      await getNewJWTToken(address, connector)
      console.log('authenticate success')

      // 2. fetch user data
      let userData
      try {
        userData = await fetchUserData(address)
      } catch (err) {
        console.error('fetchUserData err', err)
        disconnect()
        return
      }

      // 3. determine if user is setup, wait to set current user for new user
      if (userData && userData.displayName) {
        setCurrentUser(userData)
        setStatus('authenticated')
      } else {
        setStatus('new-user')
      }
      setStatus('authenticated')
      navigate('/')
    } catch (error) {
      console.error('authenticate err', error)
    }
  }

  const onAccountChange = async (data) => {
    console.log('onAccountChange', { data })
    // three states: connected, disconnected, reconnecting
    // connected: check if this address is the current authenticated user
    // disconnected: remove all state variables
    // reconnecting: do nothing

    // Reconnecting
    if (data.isReconnecting) {
      // user is reconnecting, do nothing
      return
    }

    // Disconnected
    if (data.isDisconnected) {
      setCurrentUser(undefined)
      localStorage.removeItem('address')
      setStatus('disconnected')
      return
    }

    // Connecting
    if (data.isConnecting) {
      // user is connecting, do nothing
      return
    }

    // Connected

    // Block OFAC sanctioned addresses
    if (OFAC_SANCTIONED_ADDRESSES.includes(data.address.toLowerCase())) {
      console.error('OFAC sanctioned address')
      disconnect()
      setStatus('blocked')
      return
    }

    // is this address the current address?
    if (data.address === address) {
      console.log('address match', { address, data })
      return
    }
    setStatus('loading')
    // new address, perform login

    // 0. remove any variables asscociated with previous address
    localStorage.removeItem('address')
    setAddress('')
    setCurrentUser(undefined)
    unsetAxiosAuthInstance()

    // 1. check JWT token
    let JWTToken = localStorage.getItem(data.address)

    // token valid, user broken: continue
    // token valid, user not broken: continue
    // token not valid, user broken: get new token
    // token not valid, user not broken: abort login

    // if ((!JWTToken || !validateJWTTokenExpiration(JWTToken)) && userBroken) {
    //     // get new token
    //     try {
    //         JWTToken = await getNewJWTToken(data.address, data.connector)
    //     } catch (err) {
    //         console.error('getNewJWTToken err', err)
    //         disconnect()
    //         return
    //     }
    // } else if ((!JWTToken || !validateJWTTokenExpiration(JWTToken)) && !userBroken) {
    //     // token is not valid and user didn't click connect, abort login
    //     disconnect()
    //     return
    // }

    // set axios auth instance
    setAxiosAuthInstance(JWTToken)

    // 2. fetch user data
    // let userData
    // try {
    //     userData = await fetchUserData(data.address)
    // } catch (err) {
    //     console.error('fetchUserData err', err)
    //     disconnect()
    //     return
    // }

    // // 3. determine if user is setup, wait to set current user for new user
    // if (userData && userData.displayName) {
    //     setCurrentUser(userData)
    //     setStatus('authenticated')
    // } else {
    //     setStatus('new-user')
    // }

    // 4. set state variables
    setAddress(data.address)
    localStorage.setItem('address', data.address)
    localStorage.setItem(data.address, JWTToken)
  }

  const setCurrentUserWrapper = async (user) => {
    // check if this is from new user setting up profile
    if (status === 'new-user') {
      // this shouldn't happen
      if (user.ethAddress !== address) {
        console.error('Address mismatch', { address, user })
        throw new Error('Address mismatch')
      } else {
        setStatus('authenticated')
      }
    }
    setCurrentUser(user)
  }

  const disconnect = () => {
    // remove state variables
    setCurrentUser(undefined)
    localStorage.removeItem('address')
    setStatus('disconnected')
    unsetAxiosAuthInstance()

    // navigate to home
    navigate('/')

    // disconnect from wallet
    wagmiDisconnect()
  }

  React.useEffect(() => {
    const unwatch = watchAccount(wagmiConfig, {
      onChange: async (data) => {
        onAccountChange(data)
      },
    })
    return unwatch
  }, [])

  return {
    address,
    currentUser,
    status,
    setCurrentUser: setCurrentUserWrapper,
    disconnect,
    open,
    authenticate,
  }
}
