import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider, createConfig, http } from 'wagmi'
import { base, baseSepolia, mainnet } from 'viem/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { injected, metaMask, coinbaseWallet } from '@wagmi/connectors'
import { createSIWE } from '../services/SiweService'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Your WalletConnect Cloud project ID
const projectId = 'f9b7f121573a0a15d467393e8542df07'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url:
    process.env.NODE_ENV === 'staging'
      ? 'https://staging.tippingpoint.app'
      : 'http://localhost:3001',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const connectors = [
  coinbaseWallet({
    enableMobileWalletLink: true,
    version: '4',
    appName: metadata?.name ?? 'Unknown',
    appLogoUrl: metadata?.icons[0] ?? 'Unknown',
    preference: 'all',
  }),
  metaMask(),
]

export const oldCconfig = createConfig({
  chains: [mainnet],
  transports: {
    [mainnet.id]: http('https://mainnet.example.com'),
  },
  connectors: connectors,
})

const chains =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? [base] : [base]
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
})

// 3. Create a SIWE configuration object
const siweConfig = createSIWE(chains.map((chain) => chain.id))

// 4. Create modal
createWeb3Modal({ wagmiConfig: config, projectId, siweConfig, metadata })

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}

// import { WagmiProvider, createConfig, http } from "wagmi";
// import { baseSepolia, } from "wagmi/chains";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ConnectKitProvider, getDefaultConfig } from "connectkit";

// const config = createConfig(
//   getDefaultConfig({
//     // Your dApps chains
//     chains: [baseSepolia],
//     transports: {
//       // RPC URL for each chain
//       [baseSepolia.id]: http(
//         'https://base-sepolia.g.alchemy.com/v2/zQPX8N6M4IU5viwH0wa676TdA2JP5OXC'
//       ),
//     },

//     // Required API Keys
//     walletConnectProjectId: 'f9b7f121573a0a15d467393e8542df07',

//     // Required App Info
//     appName: "Your App Name",

//     // Optional App Info
//     appDescription: "Your App Description",
//     appUrl: "https://family.co", // your app's url
//     appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
//   }),
// );

// const queryClient = new QueryClient();

// export const Web3ModalProvider = ({ children }) => {
//   return (
//     <WagmiProvider config={config}>
//       <QueryClientProvider client={queryClient}>
//         <ConnectKitProvider>{children}</ConnectKitProvider>
//       </QueryClientProvider>
//     </WagmiProvider>
//   );
// };
