import axios from 'axios'

const STAGING_BACKEND_URL =
  'https://tipping-point-backend-staging-87ca682c565d.herokuapp.com/'
const DEVELOPMENT_BACKEND_URL = 'http://127.0.0.1:3000/'
const PRODUCTION_BACKEND_URL = 'https://tipping-point-backend-prod-d352a4d81cf4.herokuapp.com/'

export function getBackendUrl(){
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return PRODUCTION_BACKEND_URL;
      case 'staging':
      return STAGING_BACKEND_URL;
      case 'development':
      return DEVELOPMENT_BACKEND_URL;
    default:
      return '';
  }
}

export let axiosInstance = axios.create({
  baseURL: getBackendUrl(),
  timeout: 10000,
  headers: {
    'X-Custom-Header': 'foobar',
    Authorization: `Bearer ${localStorage.getItem(localStorage.getItem('ethAddress'))}`,
  },
})

export const setAxiosAuthInstance = (token) => {
  axiosInstance = axios.create({
    baseURL: getBackendUrl(),
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return axiosInstance
}

export const unsetAxiosAuthInstance = () => {
  axiosInstance = axios.create({
    baseURL: getBackendUrl(),
    timeout: 10000,
  })
  return axiosInstance
}
